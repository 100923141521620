@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chonburi&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Meie+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

.about_aboutWrapper__2fPCH{background:#262626;color:white;height:100vh;font-family:"Inconsolata", monospace;font-weight:600;align-items:center;display:flex;flex-direction:column}.about_yannieYeung__GtUMi{color:#84cdf4;font-size:20px}.about_software__3k-hM{color:#84cdf4}.about_function__1aynT{color:#f9f9b7;font-size:20px}.about_functionWrapper__2g9bl{margin:0 auto;text-align:left;color:#f4a788}.about_textWrapper__rbRhx{display:flex;flex-direction:column;align-content:center;padding-top:17%}.about_button__1s3i0{--color: #a972cb;--hover: #cb72aa}.about_button__1s3i0{line-height:1;margin:2em;padding:0.6em 1.7em;border-radius:23px;background:#262626;box-shadow:5px 5px 10px #1d1d1d, -5px -5px 10px #303030;border:none;margin-top:4em;font-size:13px;color:white}.about_button__1s3i0:hover,.about_button__1s3i0:focus{outline:none;border-radius:23px;background:#262626;box-shadow:inset 5px 5px 10px #1d1d1d, inset -5px -5px 10px #303030;cursor:pointer}.about_button__1s3i0:hover,.about_button__1s3i0:focus{border-radius:23px;background:#262626;box-shadow:inset 5px 5px 10px #1d1d1d, inset -5px -5px 10px #303030;cursor:pointer}.about_functionContainer__2fILc{display:flex;flex-direction:column;justify-content:center}.about_functionP__2fbww{justify-content:center;margin:0 auto;margin-top:20px}.about_closeB__3UBId{justify-content:center;margin:0px 42%}

svg{width:70vw;height:100vh;fill:#353535}.home_yannieHome__34ZJ3{display:flex}.home_yannie__1iJYc{font-family:"Chonburi", cursive;font-weight:300;font-size:6vw;margin:0;position:absolute;top:60%;left:55%;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.home_yeung__3P6be{margin:0;font-family:"Meie Script", cursive;font-weight:300;font-size:6vw;position:absolute;top:66%;padding-left:150px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.home_name__wv-cz{display:flex;flex-direction:column;justify-content:center;position:relative}.home_title__1O3Wb{position:absolute;top:55%;left:180%;width:280px;font-family:"Quicksand", sans-serif;font-size:1.6vw;letter-spacing:5px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}

.homeContainer {
  position: relative;
  --maskX: 0;
  --maskY: 50;
  margin: 0 auto;
}

.cloneWrapper {
  position: absolute;
  top: 0;
  left: 0;
  color: #ef6eae;
  /* svg {
    fill: #ef6eae;
  } */
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* // clip-path: polygon(
  //   0 0,
  //   calc(var(--maskX) * 1%) 0,
  //   calc(var(--maskY) * 1%) 100%,
  //   0 100%
  // ); */

  /* clip-path: polygon(
    0 0,
    calc(var(--maskX) * 1% + (var(--maskY)-50) * 1.2%) 0,
    calc(var(--maskX) * 1% + (var(--maskY)-50) * -0.1%) 100%,
    0 100%
  ); */
}
.cloneSvg {
  fill: #ef6eae;
}

.justThis {
  color: #353535;
}
.homeWrapper {
  margin: 0 auto;
  margin-left: 10%;
  padding-left: 10%;
}

.about {
  transform: rotate(90deg);
  float: left;
  position: absolute;
  top: 45%;
  font-size: medium;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 0;
  color: #1e1e1e;
}

.myPortfolio {
  transform: rotate(270deg);

  position: absolute;
  top: 44%;
  right: 0;
  font-size: medium;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 0;
  color: #1e1e1e;
}

.eachPort_eachWrapper__1inuG{width:40%;height:240px;margin:2%;border-radius:23px;background:#262626;box-shadow:16px 16px 28px #1f1f1f, -16px -16px 28px #2d2d2d;border:none;position:relative;overflow:hidden}.eachPort_eachWrapper__1inuG:hover{border-radius:23px;background:#262626;box-shadow:inset 16px 16px 15px #1f1f1f, inset -16px -16px 15px #2d2d2d;border:none;cursor:pointer}.eachPort_allWrapper__MqS6W{display:flex;flex-wrap:wrap;padding-left:7%;padding-top:5%}.eachPort_title__1SK3b{padding-left:6%;text-decoration:none;color:#489ee4;margin-bottom:1%}.eachPort_link__HcJxl{text-decoration:none}.eachPort_description__1UKWy{width:100%;height:100%;position:absolute;top:93%;background-color:white;opacity:0.2;color:black}.eachPort_description__1UKWy:hover{position:absolute;top:23%;background-color:white;opacity:0.4;transform:scale(1);transition:all 1s ease}.eachPort_description__1UKWy>p{padding-left:3%;padding-right:3%}

.animation_container__2zzvB{display:flex;width:76%;margin:0 auto}

.allPort_allPortfoliosContainer__1daYG{background:#262626;color:white;height:100vh}

.contact_wrapper__ukiu5{background:#242424;color:white;height:100vh;font-family:"Inconsolata", monospace;font-weight:600;align-items:center;display:flex;flex-direction:column}.contact_email__2fNa-{color:#84cdf4;font-size:20px}.contact_linkedin__1Np25{color:#489ee4;font-size:20px;transition:0.25s}.contact_linkedinWrapper__1uNdE{color:white;text-decoration:none}.contact_linkedin__1Np25:hover{cursor:pointer;transform:translateY(10px)}.contact_textWrapper__18hpc{margin-top:18%}.contact_button__1gria{--color: #a972cb;--hover: #cb72aa}.contact_button__1gria{font-size:12px;line-height:1;margin:2em;padding:0.6em 1.7em;border-radius:23px;background:#262626;box-shadow:5px 5px 10px #1d1d1d, -5px -5px 10px #303030;margin-top:130%;border:none;color:white}.contact_button__1gria:hover,.contact_button__1gria:focus{outline:none;border-radius:23px;background:#262626;box-shadow:inset 5px 5px 10px #1d1d1d, inset -5px -5px 10px #303030;cursor:pointer}.contact_button__1gria:hover,.contact_button__1gria:focus{border-radius:23px;background:#262626;box-shadow:inset 5px 5px 10px #1d1d1d, inset -5px -5px 10px #303030;cursor:pointer}

