@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");

.homeContainer {
  position: relative;
  --maskX: 0;
  --maskY: 50;
  margin: 0 auto;
}

.cloneWrapper {
  position: absolute;
  top: 0;
  left: 0;
  color: #ef6eae;
  /* svg {
    fill: #ef6eae;
  } */
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* // clip-path: polygon(
  //   0 0,
  //   calc(var(--maskX) * 1%) 0,
  //   calc(var(--maskY) * 1%) 100%,
  //   0 100%
  // ); */

  /* clip-path: polygon(
    0 0,
    calc(var(--maskX) * 1% + (var(--maskY)-50) * 1.2%) 0,
    calc(var(--maskX) * 1% + (var(--maskY)-50) * -0.1%) 100%,
    0 100%
  ); */
}
.cloneSvg {
  fill: #ef6eae;
}

.justThis {
  color: #353535;
}
.homeWrapper {
  margin: 0 auto;
  margin-left: 10%;
  padding-left: 10%;
}

.about {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  float: left;
  position: absolute;
  top: 45%;
  font-size: medium;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 0;
  color: #1e1e1e;
}

.myPortfolio {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);

  position: absolute;
  top: 44%;
  right: 0;
  font-size: medium;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 0;
  color: #1e1e1e;
}
